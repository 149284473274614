import React, { HTMLAttributes } from 'react'
import { Box } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

type TProps = {
  srcProps: HTMLAttributes<HTMLSourceElement>
}

const useStyles = makeStyles(() => createStyles({
  video: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: '70vh',
  },
}))

export const HowToVideo = (props: TProps) => {
  const {
    srcProps,
  } = props
  const styles = useStyles()
  return (
    <Box my={2} width={1} maxWidth={1}>
      <video
        width="100%"
        className={styles.video}
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
      >
        <source type="video/mp4" {...srcProps} />
        Sorry, your browser doesn't support embedded videos
      </video>
    </Box>
  )
}

export default HowToVideo
