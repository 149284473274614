export const relocateToSignIn = () => {
  if(window.location.pathname.indexOf('/signup') === -1) {
    const postSignInUrl = encodeURIComponent(window.location.pathname)
    const url = new URL(window.location.href)
    url.pathname = '/signup/'
    url.searchParams.set('after', postSignInUrl)

    if(url.searchParams.has('norel') || url.searchParams.has('norelocate')) {
      // eslint-disable-next-line no-console
      console.log('No-relocate specified in url parameters. Skipping relocation.')
    } else {
      window.location.href = url.href
    }
  }
  return null
}

export const RelocateToSignIn = relocateToSignIn

export default RelocateToSignIn
