import React from 'react'
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import { ErrorWithStatusCode } from 'contexts/api/FetchError'

import ComicallyLargeEmoji from './ComicallyLargeEmoji'

type Props = {
  error: Error & ErrorWithStatusCode
  eventId?: string
}

export const CatastrophicError = (props: Props) => {
  const {
    error,
    eventId,
  } = props

  // @ts-ignore
  const hasSentry = typeof window !== 'undefined' && typeof window.__SENTRY__ !== 'undefined'

  return (
    <>
      <Box textAlign="center" p={4}>
        <ComicallyLargeEmoji>🤕</ComicallyLargeEmoji>
        <Typography variant="h3">Something went wrong</Typography>
        <Typography variant="body2" component="div">
          <p>
            Sorry! An error occurred that we're not sure how to handle.
          </p>
          {hasSentry && (
            <>
              <Box>
                <Button type="button" variant="contained" color="primary" onClick={() => Sentry.showReportDialog({ eventId })}>Report feedback</Button>
              </Box>
              <p>The error has been logged and our team will investigate shortly.</p>
            </>
          )}
          <p>
            If the problem persists or you need immediate assistance, please call our Customer Engagement Specialists at <a href="tel:+1-216-251-2510">216-251-2510</a>.
          </p>
        </Typography>
      </Box>
      {error && (
        <Box width="100%" p={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                {error.name}: {error.message}
              </Typography>
              <Box
                component="pre"
                fontFamily="Monospace"
                width={1}
                maxWidth={1}
                overflow="scroll"
                fontSize="x-small"
              >
                <code>{error.stack}</code>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  )
}

export default CatastrophicError
