import React, { ReactNode, useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import Debug from 'debug'

import useSiteMetadata from 'hooks/useSiteMetadata'

const log = Debug('AL:SentryProvider')

export const SentryProvider = (props: { children?: ReactNode}) => {
  const { children } = props
  const siteMetadata = useSiteMetadata()
  useEffect(() => {
    const env = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'
    if(env !== 'development') {
      log(`Initializing sentry with environment: "${env}" @${siteMetadata.hash}`)
      Sentry.init({
        dsn: 'https://cc3e473f52fd4d368a2535e102896b48@sentry.io/1515124',
        environment: env,
        release: siteMetadata.hash,
      })
    }
  }, [siteMetadata.hash])

  return (
    <>
      {children}
    </>
  )
}
export default SentryProvider

