import React, { useMemo, useState } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { Box, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { questions } from './frequent-questions'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  expandedContent: {
    fontWeight: 'bold',
  },
}))

export const FAQ = () => {
  const classes = useStyles()
  const [expandedKey, setExpandedKey] = useState<string | undefined>()

  const handleChange = (panel: string) => (_event: unknown, isExpanded: boolean) => {
    setExpandedKey(isExpanded ? panel : undefined)
  }

  const panels = useMemo(() => {
    return questions.map(q => {
      const isExpanded = expandedKey === q.key
      return (
        <ExpansionPanel expanded={isExpanded} onChange={handleChange(q.key)} key={q.key}>
          <ExpansionPanelSummary
            expandIcon={<MdExpandMore />}
            aria-controls={`panel-${q.key}-content`}
            id={`panel-${q.key}-header`}
          >
            {typeof q.question === 'string' ? (
              <Typography className={isExpanded ? classes.expandedContent : undefined}>{q.question}</Typography>
            ) : q.question}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {typeof q.answer === 'string' ? (
              <Typography>{q.answer}</Typography>
            ) : q.answer}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )
    })
  }, [classes.expandedContent, expandedKey])
  return (
    <div className={classes.root}>
      <Box my={2}>
        <Typography variant="h2">Frequently Asked Questions</Typography>
      </Box>

      {panels}
    </div>
  )
}
