import { useCallback, useState } from 'react'

export type TMappedTimeoutProps = {
  defaultDelay?: number,
}

export type TMappedTimeout = {
  startTimeout: (key: string, callback: Function, delay?: number) => number
  clearTimeoutByKey: (key: string) => void
}
export function useMappedTimeout(props: TMappedTimeoutProps = {}): TMappedTimeout {
  const { defaultDelay = 5000 } = props
  const [timeouts, setTimeouts] = useState<Map<string, number>>(new Map())
  const startTimeout = useCallback((key: string, callback: Function, delay: number = defaultDelay): number => {
    const tid = window.setTimeout(callback, delay)
    timeouts.set(key, tid)
    setTimeouts(timeouts)
    return tid
  }, [defaultDelay, timeouts])

  const clearTimeoutByKey = useCallback((key: string): void => {
    const tid = timeouts.get(key)
    if(tid) {
      window.clearTimeout(tid)
    }
  }, [timeouts])

  return {
    startTimeout,
    clearTimeoutByKey,
  }
}
