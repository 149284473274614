import React, { ReactNode } from 'react'

const styles = require('./ComicallyLargeEmoji.css')

const ComicallyLargeEmoji = ({ children }: { children: ReactNode }) => (
  <div className={styles.root}>
    <span role="img">{children}</span>
  </div>
)

export default ComicallyLargeEmoji
