import React, { ReactNode, useCallback } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import Debug from 'debug'
import _set from 'lodash.set'

import context, { defaultSettings, TSettings } from './context'

const { Provider } = context

const log = Debug('AL:Settings')

export interface Props {
  children: ReactNode
}

export const SettingsProvider = (props: Props) => {
  const { children } = props
  const [storedSettings, setStoredSettings] = useLocalStorage<TSettings>('AL-settings', defaultSettings)
  const setValue = useCallback((k: string, v: any) => {
    log(`Setting "${k}" => "${v}"`)
    const next = _set(storedSettings, k, v)
    setStoredSettings(next)
  }, [setStoredSettings, storedSettings])
  return (
    <Provider
      value={{
        settings: storedSettings,
        set: setValue,
      }}
    >
      {children}
    </Provider>
  )
}

export default SettingsProvider
