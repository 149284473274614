/**
 * Based on react-use/lib/useSessionStorage.ts but watches for storage change
 * events as well.
 * @author Aaron Lampros <alampros@testoil.com>
 */

import { useEffect, useState } from 'react'

const useSessionStorage = <T, >(key: string, initialValue?: T, raw?: boolean): [T, (value: T) => void] => {
  const [state, setState] = useState<T>(() => {
    try {
      if(typeof window === 'undefined') {
        return initialValue
      }
      const sessionStorageValue = sessionStorage.getItem(key)
      if(typeof sessionStorageValue !== 'string') {
        sessionStorage.setItem(key, raw ? String(initialValue) : JSON.stringify(initialValue))
        return initialValue
      } else {
        return raw ? sessionStorageValue : JSON.parse(sessionStorageValue || 'null')
      }
    } catch{
      // If user is in private mode or has storage restriction sessionStorage
      // can throw. JSON.parse and JSON.stringify can throw, too.
      return initialValue
    }
  })

  useEffect(() => {
    try {
      if(typeof window === 'undefined') {
        return
      }
      const serializedState = raw ? String(state) : JSON.stringify(state)
      sessionStorage.setItem(key, serializedState)
    } catch{
    }
  })

  useEffect(() => {
    function listen(e: StorageEvent) {
      if(e.storageArea === sessionStorage && e.key === key) {
        const deserialized = raw ? e.newValue : JSON.parse(e.newValue || 'null')
        setState(deserialized)
      }
    }
    window.addEventListener('storage', listen)
    return () => {
      window.removeEventListener('storage', listen)
    }
  }, [key, raw])

  return [state, setState]
}

export default useSessionStorage
