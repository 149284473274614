import React, { ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import useNetwork from 'react-use/lib/useNetwork'
import { settingsContext } from 'contexts/Settings'
import Debug from 'debug'

import useKeyToggle from 'hooks/useKeyToggle'
import usePrevious from 'hooks/usePrevious'
import { useTimeout } from 'hooks/useTimeout'

import context, { defaultContext } from './context'
import { useMappedTimeout } from './useMappedTimeout'

const { Provider } = context

const log = Debug('AL:NetworkState')

export interface Props {
  children: ReactNode
}

export const NetworkStateProvider = (props: Props) => {
  const {
    children,
  } = props
  const [voluntaryOffline, setVoluntaryOffline] = useKeyToggle(e => e.key === 'o', false)
  const [requestHasTimedOut, setRequestHasTimedOut] = useState<boolean>(false)
  const networkState = useNetwork(defaultContext)
  const { settings: { networkTimeoutDelay } } = useContext(settingsContext)

  const [fakeRequestHasTimedOut, setFakeRequestHasTimedOut] = useKeyToggle(e => e.key === 't', false)

  const { startTimeout, clearTimeoutByKey } = useMappedTimeout({ defaultDelay: networkTimeoutDelay })

  const online = useMemo<boolean>(() => {
    if(networkState.online === false) {
      return false
    }
    if(voluntaryOffline) {
      return false
    }
    return true
  }, [networkState.online, voluntaryOffline])

  useEffect(() => {
    if(online) {
      setRequestHasTimedOut(false)
      setFakeRequestHasTimedOut(false)
    }
  }, [online, setFakeRequestHasTimedOut])

  useEffect(() => {
    if(fakeRequestHasTimedOut === true) {
      setRequestHasTimedOut(true)
    } else {
      setRequestHasTimedOut(false)
    }
  }, [fakeRequestHasTimedOut])

  const resetRequestHasTimedOutAfter: number | null = useMemo(() => {
    if(requestHasTimedOut) {
      return 1000 * 30
    }
    return null
  }, [requestHasTimedOut])

  useTimeout(() => {
    setRequestHasTimedOut(false)
  }, resetRequestHasTimedOutAfter)

  const previousOnline = usePrevious<boolean>(online)

  const handleNetworkTimeout = (url: string) => {
    log('⏰ TIMED OUT!', url)
    setRequestHasTimedOut(true)
  }

  const clearNetworkTimeout = useCallback((req: RequestInfo) => {
    const url = typeof req === 'string' ? req : req.url
    clearTimeoutByKey(url)
  }, [clearTimeoutByKey])

  const startNetworkTimeout = useCallback((req: RequestInfo) => {
    const url = typeof req === 'string' ? req : req.url
    clearTimeoutByKey(url)
    if(online) {
      startTimeout(url, handleNetworkTimeout)
    } else {
      log('Not starting network timeout. Already offline.')
    }
  }, [clearTimeoutByKey, online, startTimeout])

  useLayoutEffect(() => {
    if(typeof previousOnline !== 'undefined' && online !== previousOnline) {
      if(online) {
        log('%c🔌 Online', 'background: green;padding: 1px 3px;')
        try {
          if('FS' in window) {
            window.FS.event('Network State Change', {
              online_bool: online,
            })
          }
        } catch(err) {
          console.warn(err)
        }
      } else {
        log('%c🔌 Offline', 'background: red;padding: 1px 3px;')
        try {
          if('FS' in window) {
            window.FS.event('Network State Change', {
              online_bool: online,
            })
          }
        } catch(err) {
          console.warn(err)
        }
      }
    }
  }, [previousOnline, online])

  return (
    <Provider
      value={{
        online,
        networkState,
        clearNetworkTimeout,
        startNetworkTimeout,
        voluntaryOffline,
        setVoluntaryOffline,
        requestHasTimedOut,
        setRequestHasTimedOut,
      }}
    >
      {children}
    </Provider>
  )
}

export default NetworkStateProvider
