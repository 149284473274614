export interface IInterceptedResponseConfig<T> {
  status: number;
  error?: string;
  isError?: boolean;
  data?: T;
}

export default class InterceptedResponse<T = any> {
  status: number = 200;
  error?: string;
  isError: boolean = false;
  data?: T;
  constructor(p: IInterceptedResponseConfig<T>) {
    this.status = p.status || 200;
    this.error = p.error;
    this.isError = p.isError ?? !!p.error;
    this.data = p.data;
  }
}
