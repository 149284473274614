import { useEffect, useRef } from 'react'

export function useTimeout(callback: Function, delay: number | null, restartDependencies: any[] = []): { timeoutId?: number } {
  const savedCallback = useRef<Function>()
  const idRef = useRef<number>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  const start = () => {
    if(delay !== null) {
      window.clearTimeout(idRef.current)
      idRef.current = window.setTimeout(() => {
        savedCallback.current && savedCallback.current()
      }, delay)
    }
  }

  // Restart the timer if the restartDependencies change
  useEffect(() => {
    start()
    return () => clearTimeout(idRef.current)
  }, restartDependencies) // eslint-disable-line react-hooks/exhaustive-deps

  // Restart or stop the timer if the delay changes
  useEffect(() => {
    start()
    if(delay === null) {
      window.clearTimeout(idRef.current)
      idRef.current = undefined
    }
    return () => clearTimeout(idRef.current)
  }, [delay]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    timeoutId: idRef.current,
  }
}
