import React from 'react'
import { NetworkState } from 'react-use/lib/useNetwork'

export type NetworkStateContextType = {
  online?: boolean,
  networkState?: NetworkState,
  requestHasTimedOut: boolean,
  setRequestHasTimedOut: (val: boolean) => void,
  voluntaryOffline: boolean,
  setVoluntaryOffline: (val: boolean) => void,
  startNetworkTimeout: (url: RequestInfo) => void,
  clearNetworkTimeout: (url: RequestInfo) => void,
}

export const defaultState: NetworkState = { online: typeof navigator !== 'undefined' ? navigator.onLine : true }

export const defaultContext: NetworkStateContextType = {
  online: defaultState.online,
  networkState: defaultState,
  requestHasTimedOut: false,
  setRequestHasTimedOut: () => console.error('NOT IMPLEMENTED'),
  voluntaryOffline: false,
  setVoluntaryOffline: () => console.error('NOT IMPLEMENTED'),
  startNetworkTimeout: () => console.error('NOT IMPLEMENTED'),
  clearNetworkTimeout: () => console.error('NOT IMPLEMENTED'),
}

export default React.createContext<NetworkStateContextType>(defaultContext)
