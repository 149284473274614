import { useLayoutEffect, useState } from 'react'
import * as consoleBadge from 'console-badge'

import useSiteMetadata from 'hooks/useSiteMetadata'

export const VersionLogger = () => {
  const {
    shortHash,
    branch,
    package: pkg,
  } = useSiteMetadata()
  const [didLog, setLogged] = useState<boolean>(false)

  useLayoutEffect(() => {
    setLogged(true)
  }, [])

  if(!didLog) {
    consoleBadge.log({
      mode: 'shields.io',
      leftText: pkg.name,
      rightText: `${pkg.version} @${shortHash} (${branch})`,
      rightBgColor: '#ffc107',
      rightTextColor: '#1a1a1a',
    })
  }

  return null
}

export default VersionLogger
