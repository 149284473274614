import { createFetchError } from "./FetchError";
import { HttpError } from "./HttpError";
import InterceptedResponse from "./InterceptedResponse";
import RelocateToSignIn from "./RelocateToSignIn";

export const baseParser = async <T>(
  _url: RequestInfo,
  _opts: RequestInit | undefined,
  resp: Response | InterceptedResponse
): Promise<T> => {
  if (resp instanceof InterceptedResponse) {
    const ok = typeof resp.isError !== "undefined" && !resp.isError;
    if (ok) return resp.data;

    return Promise.reject(
      new HttpError(
        resp.status,
        resp.error ? `${resp.status}: ${resp.error}` : ""
      )
    );
  }

  if (resp.ok) {
    const contentType = resp.headers.get("content-type");
    let body;
    try {
      if (contentType && contentType.includes("json")) {
        body = await resp.json();
      } else {
        body = await resp.text();
      }
    } catch (err) {
      console.warn("Could not parse JSON response from text:", err);
      body = resp.body;
    }
    return body;
  } else {
    if (resp.status === 401) {
      RelocateToSignIn();
    }
    return Promise.reject(await createFetchError(resp.clone()));
  }
};

export default baseParser;
