export function isUnprefixedAPIUrl(url: RequestInfo, testRe: RegExp = /^(http|\/)/i): boolean {
  const urlStr = typeof url === 'string' ? url : url.url
  if(!urlStr) return false
  return !testRe.test(urlStr)
}

export const baseFetch = async (url: RequestInfo, opts: RequestInit = {}): Promise<Response> => {
  if(isUnprefixedAPIUrl(url)) {
    console.warn('Fetch URL may not be properly prefixed:', url)
  }
  const { headers = new Headers(), ...rest } = opts || {}
  const headersWithDefaults = new Headers(headers || {})
  headersWithDefaults.set('accept', 'application/json')
  const fetchConfig = ({
    mode: 'cors',
    credentials: 'include',
    headers: headersWithDefaults,
    ...rest,
  } as Partial<RequestInit>)
  return fetch(url, fetchConfig)
}

export default baseFetch
