import React, { ReactNode, useMemo } from 'react'
import Debug from 'debug'

import IncompatibleBrowser from 'components/Errors/IncompatibleBrowser'

const log = Debug('AL:CompatibilityCheck')

type CompatibilityTest = {
  name: string
  test: () => boolean
}
const tests: CompatibilityTest[] = [
  {
    name: 'url',
    test: () => {
      let url
      try {
        // have to actually try use it, because Safari defines a dud constructor
        url = new URL('http://modernizr.com/')
        return url.href === 'http://modernizr.com/'
      } catch(e) {
        return false
      }
    },
  },
  {
    name: 'url search params',
    test: () => typeof window !== 'undefined' && 'URLSearchParams' in window,
  },
  {
    name: 'abort controller',
    test: () => typeof window !== 'undefined' && 'AbortController' in window,
  }
]

export const CompatibilityCheck = (props: { children: ReactNode }) => {
  const {
    children,
  } = props

  const passed = useMemo<boolean>(() => {
    if(typeof window === 'undefined') {
      return true
    }
    log(`Testing compatibility with ${tests.length} tests...`)
    return tests.every(t => {
      const testPassed = t.test()
      if(!testPassed) {
        console.warn(`CompatibilityCheck: Test "${t.name}" failed.`)
        log(`Test "${t.name}" failed.`)
      }
      return testPassed
    })
  }, [])

  if(!passed) {
    return (
      <IncompatibleBrowser />
    )
  }
  return (
    <>
      {children}
    </>
  )
}

export default CompatibilityCheck
