import React, { ReactNode } from 'react'
import { Box, Typography } from '@material-ui/core'
import { Link } from 'gatsby'

export type TQuestion = {
  question: string | ReactNode
  answer: string | ReactNode
  key: string
}

export const questions: TQuestion[] = [
  {
    key: 'explosives',
    question: 'I need to sample in an area which explosive gases are present and cannot use cell phones. Can I use a Class 1 Division 2 compliant scanner?',
    answer: (
      <Box flexDirection="column">
        <Typography paragraph>The device used with AssetLink must have access to the internet at least an hour before sampling (see <Link to="/help/offline-mode">Offline Mode</Link>). There are phone cases available and computers available that meet the Class 1 Division 2 requirement. <a href="https://intrinsicallysafestore.com/">intrinsicallysafestore.com</a> offers Class 1 Division 2 phone cases and other products.</Typography>
        <Typography paragraph>Another option is that AssetLink can be used on a phone before and entering these areas. Samples can be linked before entering or after exiting while you are located in a safe area.</Typography>
      </Box>
    ),
  },
  {
    key: 'notag',
    question: 'Can I use AssetLink to link samples to assets that have not been tagged?',
    answer: 'Currently, no. However, a feature is in the works to allow this.',
  },
  {
    key: 'unlink',
    question: 'I linked a bottle to the wrong tag. How can I fix it?',
    answer: 'From the Link screen with no asset selected, scan the bottle or enter the ID manually. If a link exists for the bottle, the asset it was linked to will populate. Between the two, you may click the "unlink" button to delete the linkage between this asset and bottle.',
  },
  {
    key: 'delete-tag',
    question: 'Can I remove a tag from an asset?',
    answer: (
      <Box flexDirection="column">
        <Typography paragraph>Physically, yes. Unfortunately, there is not currently a means to delete a tag in the system without contacting support to do it manually.</Typography>
        <Typography paragraph>If needed, you may <Link to="/help/replace-tag">replace the tag</Link> instead.</Typography>
      </Box>
    ),
  },
]
