import React, { ReactNode, useContext } from 'react'
import { SnackbarProps } from '@material-ui/core/Snackbar'
import { settingsContext } from 'contexts/Settings'
import { SnackbarProvider as NotiSnackbarProvider } from 'notistack'

export interface Props extends Partial<Omit<SnackbarProps, 'onClose' | 'onEnter' | 'onEntering' | 'children'>> {
  children: ReactNode | undefined
}

export const SnackbarProvider = (props: Props) => {
  const { children, ...passedProps } = props
  const { settings } = useContext(settingsContext)
  return (
    <NotiSnackbarProvider {...settings.notifications} {...passedProps}>
      <>
        {children}
      </>
    </NotiSnackbarProvider>
  )
}

export default SnackbarProvider
