import { useEffect } from 'react'

import useSessionStorage from 'hooks/useSessionStorage'

export default function useApiPrefix(): string | undefined {
  const [apiPrefix, setApiPrefix] = useSessionStorage('apiPrefix', process.env.GATSBY_ASSETLINK_API_URL_PREFIX)
  if(!apiPrefix) {
    throw new Error('No API prefix is set! 🤮')
  }
  const endsWithSlash = apiPrefix.endsWith('/')
  useEffect(() => {
    if(!endsWithSlash) {
      console.warn('Your API prefix does not end in a slash. It will be added for you, but it will cost an extra render cycle.')
      setApiPrefix(apiPrefix + '/')
    }
  }, [apiPrefix, endsWithSlash, setApiPrefix])

  return apiPrefix
}
