import React from 'react'
import { Box, Typography } from '@material-ui/core'

import ComicallyLargeEmoji from './ComicallyLargeEmoji'

export const Forbidden = () => {
  return (
    <Box textAlign="center" p={4}>
      <ComicallyLargeEmoji>🔐</ComicallyLargeEmoji>
      <Typography variant="h3">Unauthorized</Typography>
      <p>
        You are not authorized to view this resource.
      </p>
    </Box>
  )
}

export default Forbidden
