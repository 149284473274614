export interface ErrorWithStatusCode extends Error {
  statusCode?: number;
}

export interface FetchError extends ErrorWithStatusCode {
  response?: Response;
  body?: string | null;
}

export const createFetchError = async (response: Response): Promise<FetchError> => {
  const err: FetchError = new Error(`${response.status} ${response.statusText}`)
  err.response = response
  err.statusCode = response.status
  err.name = 'FetchError'
  if(response) {
    try {
      const contentType = response.headers.get('content-type')
      let body
      if(contentType && contentType.includes('json')) {
        body = await response.json()
      } else {
        body = await response.text()
      }
      err.body = body
    } catch(err) {
      console.warn('Could not parse error response body', err)
    }
  }
  return err
}
