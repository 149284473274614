import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'

import { useTimeout } from 'hooks/useTimeout'

import ComicallyLargeEmoji from './ComicallyLargeEmoji'

export const Unauthorized = () => {
  const [showUnauthorized, setShowUnauthorized] = useState<boolean>(false)

  useTimeout(() => {
    setShowUnauthorized(true)
  }, 300)

  if(showUnauthorized) {
    return (
      <Box textAlign="center" p={4}>
        <ComicallyLargeEmoji>🔐</ComicallyLargeEmoji>
        <Typography variant="h3">Unauthorized</Typography>
        <p>
          Please sign in to view this resource.
        </p>
      </Box>
    )
  }
  return null
}

export default Unauthorized
