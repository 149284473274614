import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'

import useApiPrefix from 'hooks/useApiPrefix'
import usePrevious from 'hooks/usePrevious'

import ComicallyLargeEmoji from './ComicallyLargeEmoji'

export const ApiUnavailable = () => {
  let apiUrl
  const apiPrefix = useApiPrefix()
  const previousApiPrefix = usePrevious(apiPrefix)
  try {
    const pfx = apiPrefix || ''
    apiUrl = pfx.indexOf('http') === 0 ? new URL(pfx) : new URL(pfx, window.location.href)
  } catch(_err) {
  }

  useEffect(() => {
    if(apiPrefix && previousApiPrefix && apiPrefix !== previousApiPrefix) {
      window.location.reload()
    }
  }, [apiPrefix, previousApiPrefix])

  return (
    <Box textAlign="center" p={4}>
      <ComicallyLargeEmoji>🔌</ComicallyLargeEmoji>
      <h1>Network Error</h1>
      {apiUrl ? (
        <p>We can't reach the server at <code>{apiUrl.href}</code></p>
      ) : (
        <p>We can't reach the server.</p>
      )}
      <p><small>Sorry :(</small></p>
    </Box>
  )
}

export default ApiUnavailable
