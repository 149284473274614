/**
 * A hook to toggle a boolean value on keypress.
 *
 * @author Aaron Lampros <alampros@testoil.com>
 */

import { useEffect, useState } from 'react'
import useKeyPress from 'react-use/lib/useKeyPress'

/**
 * @param predicate Determine if an event should toggle the value
 * @param initialValue The initial value of the toggle.
 */
export const useKeyToggle = (
  predicate: (e: KeyboardEvent) => boolean,
  initialValue = false
): [boolean, (value: boolean) => void] => {
  const [value, setValue] = useState<boolean>(initialValue)
  const wrappedPredicate = (e: KeyboardEvent) => {
    try {
      // @ts-ignore
      return predicate(e) && !['INPUT', 'TEXTAREA'].includes(e.target.nodeName)
    } catch(err) {
      console.warn(err)
    }
    return false
  }
  const [isPressed] = useKeyPress(wrappedPredicate)

  useEffect(() => {
    if(isPressed) {
      setValue(!value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPressed])
  return [value, setValue]
}
export default useKeyToggle
