import React, { MutableRefObject, useLayoutEffect, useRef } from 'react'
import { FaEllipsisH, FaIndustry, FaLink, FaMapMarkerAlt, FaRoute } from 'react-icons/fa'

import { useElementDimensions } from 'hooks/useElementDimensions'

import NavItem from './NavItem'

const styles = require('./BottomNav.css')

type Props = {
  parentRef?: MutableRefObject<HTMLElement | null>
}
export const BottomNav = (props: Props) => {
  const { parentRef } = props
  const navRef = useRef<HTMLElement | null>(null)
  const navRect = useElementDimensions(navRef)

  useLayoutEffect(() => {
    const parent = parentRef && parentRef.current
    if(parent && navRect && navRect.height && navRect.height > 0) {
      parent.style.paddingBottom = navRect.height + 'px'
    }
  }, [navRect, parentRef])
  return (
    <nav className={styles.root} ref={navRef}>
      <NavItem to="/app/routes" name="Routes" icon={<FaRoute />} />
      <NavItem to="/app/plants" name="Plants" icon={<FaIndustry />} />
      <NavItem to="/app/sample" name="Link" icon={<FaLink />} domed />
      <NavItem to="/app/assets" name="Assets" icon={<FaMapMarkerAlt />} />
      <NavItem to="/app/more" icon={<FaEllipsisH />} />
    </nav>
  )
}

export default BottomNav
