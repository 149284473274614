import { grey, orange } from '@material-ui/core/colors'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

export const assetLinkThemeUnresponsive = createMuiTheme({
  palette: {
    primary: {
      main: '#005CB9',
    },
    secondary: {
      main: orange[500],
    },
    text: {
      hint: grey[600],
    },
  },
})

export const assetLinkTheme = responsiveFontSizes(assetLinkThemeUnresponsive, { factor: 4 })

export default assetLinkTheme
