import React, { ReactNode } from 'react'

export const isBrowser = typeof window !== 'undefined'

export const BrowserOnly = (props: { children: ReactNode }) => {
  const {
    children,
  } = props

  if(!isBrowser) return null
  return <>{children}</>
}

export default BrowserOnly
