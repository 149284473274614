import React from 'react'
import { List, ListItem, ListItemProps, ListItemText } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { GatsbyLinkProps, graphql, Link, useStaticQuery } from 'gatsby'

const ForwardLink = React.forwardRef<any, Omit<GatsbyLinkProps<{}>, 'ref'>>((props, ref) => {
  return (
    <Link ref={ref} {...props} />
  )
})

function ListItemLink(props: ListItemProps<'a', GatsbyLinkProps<{}> & { button?: true }>) {
  return <ListItem button component={ForwardLink} {...props} />
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}))

export const TOC = () => {
  const classes = useStyles()
  type TTOCQuery = {
    allMdx: {
      edges: {
        node: {
          id: string
          frontmatter: {
            title: string
            includeInTOC: boolean
          }
          fields: {
            slug: string
          }
        }
      }[]
    }
  }
  const data = useStaticQuery<TTOCQuery>(graphql`
    query HelpTableOfContentsQuery {
      allMdx {
        edges {
          node {
            id
            excerpt
            frontmatter {
              title
              includeInTOC
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  const pages = data.allMdx.edges.filter(({ node }) => node.frontmatter.includeInTOC !== false)
    .sort((a, b) => {
      return a.node.frontmatter.title.localeCompare(b.node.frontmatter.title)
    })
  return (
    <div className={classes.root}>
      <List component="nav">
        {pages.map(({ node }) => (
          <ListItemLink key={node.id} to={node.fields.slug}>
            <ListItemText
              primary={node.frontmatter.title}
            />
          </ListItemLink>
        ))}
      </List>
    </div>
  )
}
