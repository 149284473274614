import { graphql, useStaticQuery } from 'gatsby'

type SiteMetaData = {
  title: string
  description: string
  author: string
  shortHash: string
  hash: string
  branch: string
  package: {
    name: string
    version: string
  }
}
export const useSiteMetadata = (): SiteMetaData => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            author
            hash
            shortHash
            branch
            package {
              name
              version
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteMetadata
