import React from 'react'

import { Transaction } from 'idb/Transactions'

import InterceptedResponse from './InterceptedResponse'

export type FetchExtra = {
  transaction?: Transaction
}

export interface IAPIContext {
  fetch: <T>(input: RequestInfo, init?: RequestInit, fetchExtra?: FetchExtra) => Promise<InterceptedResponse<T> | Response>
  parseResponse: <T>(url: RequestInfo, init: RequestInit | undefined, resp: (InterceptedResponse<T> | Response), fetchExtra: FetchExtra) => (T | Promise<T>)
}

export const defaultContext: IAPIContext = {
  parseResponse: async () => {
    throw new Error('API context provider not found')
  },
  fetch: async () => {
    throw new Error('API context provider not found')
  },
}

export default React.createContext<IAPIContext>(defaultContext)
