import React from 'react'
import { globalHistory, isRedirect } from '@reach/router'
import * as Sentry from '@sentry/browser'
import { ErrorWithStatusCode } from 'contexts/api/FetchError'
import { relocateToSignIn } from 'contexts/api/RelocateToSignIn'

import BottomNav from 'components/BottomNav'

import ApiUnavailable from './ApiUnavailable'
import CatastrophicError from './CatastrophicError'
import Forbidden from './Forbidden'
import Unauthorized from './Unauthorized'

type ErrorBoundaryState = {
  errorType?: 'network'
  error?: Error & ErrorWithStatusCode
  eventId?: string
}

export default class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  state: ErrorBoundaryState = {}
  unlisten?: () => void

  static getDerivedStateFromError(error: (Error & ErrorWithStatusCode)) {
    if(error) {
      const errorType = (error.message === 'Failed to fetch' || (error.statusCode && error.statusCode > 501)) ? 'network' : undefined
      return {
        errorType,
        error,
      }
    }
    return { error: undefined, errorType: undefined }
  }

  componentDidMount() {
    this.unlisten = globalHistory.listen(this.onLocationChange)
  }

  componentWillUnmount() {
    if(this.unlisten) {
      this.unlisten()
    }
  }

  componentDidCatch(error: any, info: any) {
    if(isRedirect(error)) {
      throw error
    } else {
      if(error.statusCode !== 401) {
        Sentry.withScope(scope => {
          scope.setExtras(info)
          const eventId = Sentry.captureException(error)
          this.setState({ eventId })
        })
      }
      console.warn('Trapped error:', error, info)
    }
  }

  onLocationChange = () => {
    this.setState({
      errorType: undefined,
      error: undefined,
      eventId: undefined,
    })
  }

  render() {
    const { children } = this.props
    const {
      error,
      errorType,
      eventId,
    } = this.state
    const $err = (() => {
      if(error) {
        if(error.statusCode) {
          if(error.statusCode === 403) {
            return (
              <Forbidden />
            )
          }
          if(error.statusCode === 401) {
            relocateToSignIn()
            return (
              <Unauthorized />
            )
          }
        }
        if(errorType === 'network') {
          return <ApiUnavailable />
        }
        return (
          <CatastrophicError
            error={error}
            eventId={eventId}
          />
        )
      }
    })()
    if($err) {
      return (
        <>
          {$err}
          <BottomNav />
        </>
      )
    }
    return children
  }
}
