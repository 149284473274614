import { DependencyList, RefObject, useLayoutEffect, useState } from 'react'

function getDimensionObject(node: HTMLElement): DOMRect {
  return node.getBoundingClientRect()
}

type TReturn = DOMRect | undefined
export function useElementDimensions(ref: RefObject<HTMLElement>, deps: DependencyList = []): TReturn {
  const [dimensionsObj, setDimensions] = useState<TReturn>(undefined)
  useLayoutEffect(() => {
    const elem = ref.current
    if(elem) {
      const measure = () => {
        window.requestAnimationFrame(() => {
          setDimensions(getDimensionObject(elem))
        })
      }
      measure()
      window.addEventListener('resize', measure)
      return () => {
        window.removeEventListener('resize', measure)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...deps])
  return dimensionsObj
}
