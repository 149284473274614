import React from 'react'
import { SnackbarProviderProps } from 'notistack'

export type TSettings = {
  notifications: SnackbarProviderProps
  defaultDebugLoggingFilter: string
  defaultDebugLoggingUseColor: 1 | 0
  networkTimeoutDelay: number
}

export type SettingsContextType = {
  settings: TSettings
  set: (key: string, value: any) => any
}

export const defaultSettings: TSettings = {
  notifications: {
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    autoHideDuration: 3000,
    disableWindowBlurListener: true,
  },
  defaultDebugLoggingFilter: 'AL:*',
  defaultDebugLoggingUseColor: process.env.NODE_ENV === 'development' ? 1 : 0,
  networkTimeoutDelay: 5000,
}

export const defaultState: SettingsContextType = {
  settings: defaultSettings,
  set: () => console.warn('NOT IMPLEMENTED'),
}

export default React.createContext<SettingsContextType>(defaultState)
