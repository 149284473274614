import React, { FC, ReactNode } from 'react'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { globalHistory } from '@reach/router'
import APIProvider from 'contexts/api/APIProvider'
import assetLinkTheme from 'contexts/assetLinkTheme'
import NetworkStateProvider from 'contexts/NetworkState/NetworkStateProvider'
import SettingsProvider from 'contexts/Settings/SettingsProvider'
import SnackbarProvider from 'contexts/SnackbarProvider'
import { QueryParamProvider } from 'use-query-params'

import BrowserOnly from 'components/BrowserOnly'
import CompatibilityCheck from 'components/CompatibilityCheck'
import DebugLoggerConfig from 'components/DebugLoggerConfig'
import ErrorBoundary from 'components/Errors/ErrorBoundary'
import SentryProvider from 'components/SentryProvider'
import SiteHeadMetadata from 'components/SiteHeadMetadata'
import VersionLogger from 'components/VersionLogger'

import 'typeface-roboto'

import 'global.css'

export type Props = {
  children: ReactNode
}

export const DefaultLayout: FC<Props> = (props: Props) => {
  const {
    children,
  } = props
  return (
    <main id="app">
      <SentryProvider />
      <SettingsProvider>
        <BrowserOnly>
          <VersionLogger />
        </BrowserOnly>
        <ThemeProvider theme={assetLinkTheme}>
          <CompatibilityCheck>
            <NetworkStateProvider>
              <CssBaseline />
              <DebugLoggerConfig />
              <ErrorBoundary>
                <APIProvider>
                  <SiteHeadMetadata />
                  <QueryParamProvider reachHistory={globalHistory}>
                    <SnackbarProvider>
                      {children}
                    </SnackbarProvider>
                  </QueryParamProvider>
                </APIProvider>
              </ErrorBoundary>
            </NetworkStateProvider>
          </CompatibilityCheck>
        </ThemeProvider>
      </SettingsProvider>
    </main>
  )
}

export default DefaultLayout
