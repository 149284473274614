import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { Link } from 'gatsby'

const styles = require('./NavItem.css')

interface Props {
  name?: string,
  icon: ReactNode,
  to: string
  domed?: boolean
  className?: string
}

export const NavItem = (props: Props) => {
  const {
    name,
    icon,
    to,
    domed,
    className,
    ...passedProps
  } = props

  if(to.indexOf('/') < 0) {
    console.warn('Do not use relative links with this component. If needed, we have to refactor this to use Link from @reach/router. See https://github.com/gatsbyjs/gatsby/issues/6945')
  }
  return (
    <Link
      {...passedProps}
      to={to}
      activeClassName={styles.active}
      partiallyActive
      className={clsx(
        className,
        styles.root,
        {
          [styles.domed]: domed,
        }
      )}
    >
      <span className={styles.content}>
        {icon && <span className={styles.icon}>{icon}</span>}
        {name && <span className={styles.name}>{name}</span>}
      </span>
    </Link>
  )
}

export default NavItem
