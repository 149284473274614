import { FC, useContext } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import { settingsContext } from 'contexts/Settings'

export const DebugLoggerConfig: FC = () => {
  const { settings } = useContext(settingsContext)
  useLocalStorage('debug', settings.defaultDebugLoggingFilter, { raw: true })
  useLocalStorage('DEBUG_COLORS', settings.defaultDebugLoggingUseColor, { raw: true })
  return null
}

export default DebugLoggerConfig
